<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
      <div class="align-left">
      </div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons"/>
      </div>
      <div class="align-right">
        <headerbuttonstack :state="sideButtons" />
      </div>
    </div>
    <div style="display: flex; padding: 5px; margin-bottom:25px;"> <!-- border-bottom:1px solid #ccc; -->
      <div style="width: 33%; font-size: 32px; text-align: left; color: #e51550; font-weight:400; ">Import Jobs</div>
      <div style="width: 34%; display: flex; justify-content: center; align-items: flex-end;">
        <div v-if="importJob.id == null" class="prevent-select">
          <i class="fas fa-angles-left" style="margin-right: 10px;" v-on:click="currentPage = 1;"/>
          <i class="fas fa-angle-left" style="margin-right: 10px;" v-on:click="currentPage = currentPage <= 1 ? 1 : currentPage - 1;"/>
          {{ formattedCurrentPage }} / {{ pages }}
          <i class="fas fa-angle-right" style="margin-left: 10px;" v-on:click="currentPage = currentPage >= pages ? pages : currentPage + 1;" />
          <i class="fas fa-angles-right" style="margin-left: 10px;" v-on:click="currentPage = pages;" />
        </div>
      </div>
    </div>


    <div v-if="loading">
      <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br/>
      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>


    <div v-show="!loading && importJob.id == null">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Gestartet von</th>
          <th scope="col">Job gestartet</th>
          <th scope="col">Job beendet</th>
          <th scope="col">Methode</th>
          <th scope="col">Kandidaten</th>
          <th scope="col">Aktion erforderlich</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="job in paginatedFilteredJobs" :key="'employeeImportRow_'+job.id" style="line-height: 36px">
          <td style="width: 20%">{{ job.authuserName ? job.authuserName : "System" }}</td>
          <td style="width: 20%">{{ job.jobStartedConverted }}</td>
          <td style="width: 20%">{{ job.jobEndedConverted }}</td>
          <td style="width: 10%">{{ job.method.toUpperCase() }}</td>
          <td style="width: 10%">
            <i v-tooltip="'Alle Kandidaten anzeigen'"
               v-on:click="loadAndOpenCandidates(job);" class="fas fa-magnifying-glass"></i>
          </td>
          <td style="width: 20%" v-if="job.actionRequired">
            <button type="button" class="btn btn-sm btn-ml" style="margin-left: 1%"
                    v-on:click="selectImportJob(job)">Details</button>
          </td>
          <td style="width: 20%" v-if="!job.actionRequired">-</td>
        </tr>
        </tbody>
      </table>
    </div>


    <div v-show="!loading && importJob.id != null">

      <table class="table">
        <thead>
          <tr>
            <th scope="col">Interne Id</th>
            <th scope="col">Externe Id</th>
            <th scope="col">Vorname</th>
            <th scope="col">Nachname</th>
            <th scope="col">Geburtsdatum</th>
            <th scope="col">Sozialversicherungsnummer</th>
            <th scope="col"></th>
            <th scope="col">Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="candidate in inspectedCandidates">
            <tr v-if="candidate.actionRequired" :key="'employeeImportCandidateRow_'+candidate.id" style="line-height: 36px"
                v-bind:style="{'background-color' : (candidate.id == employeeCandidateDiffId) ? '#bbb' : 'var(--bg)'}">
              <td>{{ candidate.remoteId }}</td>
              <td>{{ candidate.externalId }}</td>
              <td>{{ candidate.firstName }}</td>
              <td>{{ candidate.lastName }}</td>
              <td>{{ candidate.dob }}</td>
              <td>{{ candidate.socialsecurity }}</td>
              <td>
                <i v-tooltip="'Kandidat anzeigen'"
                   v-on:click="openSingleCandidatesModal(candidate)" class="fas fa-magnifying-glass"></i>
              </td>
              <td>
                <button type="button" class="btn btn-sm btn-ml" style="margin-left: 1%"
                        v-on:click="checkCandidateAction(candidate)">Prüfen</button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>


    <b-modal size="xl" ref="modal-all-candidates" @close="closeAllCandidatesModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Kandidaten Details</div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <table style="width: 100%">
            <tr>
              <th scope="col">Interne Id</th>
              <th scope="col">Externe Id</th>
              <th scope="col">Vorname</th>
              <th scope="col">Nachname</th>
              <th scope="col">Geburtsdatum</th>
              <th scope="col">Sozialversicherungsnummer</th>
              <th scope="col"></th>
            </tr>
            <template v-for="candidate in inspectedCandidates">
              <tr :key="'employeeImportCandidatePopupRow_'+candidate.id" style="line-height: 36px">
                <td>{{ candidate.remoteId }}</td>
                <td>{{ candidate.externalId }}</td>
                <td>{{ candidate.firstName }}</td>
                <td>{{ candidate.lastName }}</td>
                <td>{{ candidate.dob }}</td>
                <td>{{ candidate.socialsecurity }}</td>
                <td>
                  <i v-tooltip="'Kandidat anzeigen'"
                     v-on:click="openSingleCandidatesModal(candidate)" class="fas fa-magnifying-glass"></i>
                  &nbsp;
                  <i v-if="candidate.actionRequired" class="fas fa-triangle-exclamation" style="color: var(--ml)" />
                </td>
              </tr>
            </template>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closeAllCandidatesModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>

    <b-modal size="lg" ref="modal-single-candidate" @close="closeSingleCandidatesModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">
            Kandidat Details
            <i v-if="inspectedCandidate.actionRequired" class="fas fa-triangle-exclamation" />
          </div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <table style="width: 100%">
            <tr>
              <th>Import gestartet von:</th>
              <td>{{ inspectedCandidatesJob && inspectedCandidatesJob.authuserName != null ? inspectedCandidatesJob.authuserName : "Unbekannt"}}</td>
            </tr>
            <tr v-if="candidateActions[inspectedCandidate.id] !== undefined">
              <th>Kandidaten Aktion durchgeführt von:</th>
              <td>{{ candidateActions[inspectedCandidate.id].authuserName != null ? candidateActions[inspectedCandidate.id].authuserName : "Unbekannt"}}</td>
            </tr>
            <tr v-if="candidateActions[inspectedCandidate.id] !== undefined">
              <th>Aktion:</th>
              <td v-if="candidateActions[inspectedCandidate.id].actionTaken === 'skip'">{{ "Mitarbeiter Import übersprungen" }}</td>
              <td v-if="candidateActions[inspectedCandidate.id].actionTaken === 'createEmployee'">{{ "Neuen Mitarbeiter aus Kandidaten angelegt" }}</td>
              <td v-if="candidateActions[inspectedCandidate.id].actionTaken === 'matchEmployee'">{{ "Kandidaten mit bestehendem Mitarbeiter verknüpft und Datenänderung übernommen" }}</td>
              <td v-if="candidateActions[inspectedCandidate.id].actionTaken === 'forceEmployeeUpdate'">{{ "Datenänderung übernommen" }}</td>
              <td v-if="candidateActions[inspectedCandidate.id].actionTaken === 'updateEmployeeWithoutCoreData'">{{ "Datenänderung ohne Kerndaten übernommen" }}</td>
            </tr>
            <tr v-if="candidateActions[inspectedCandidate.id] !== undefined">
              <th>Aktion durchgeführt:</th>
              <td>{{ candidateActions[inspectedCandidate.id].actionTakenAtConverted != null ? candidateActions[inspectedCandidate.id].actionTakenAtConverted : "&nbsp;"}}</td>
            </tr>
            <tr><td>&nbsp;</td></tr>
            <tr>
              <th>ID</th>
              <td>{{ inspectedCandidate.id != null ? inspectedCandidate.id : "&nbsp;"}}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('internalEmployeeNotFound') ? { color:'red' } : {}">Interne ID</th>
              <td>{{ inspectedCandidate.remoteId != null ? inspectedCandidate.remoteId : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired &&
              (isActionRequiredReason('internalEmployeeNotFound') || isActionRequiredReason('externalDataChanged','externalId'))
              ? { color:'red' } : {}">Externe ID</th>
              <td>{{ inspectedCandidate.externalId != null ? inspectedCandidate.externalId : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Firmen ID</th>
              <td>{{ inspectedCandidate.companyId != null ? inspectedCandidate.companyId : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && !inspectedCandidate.storeId
              ? { color:'red' } : {}">Store ID</th>
              <td>{{ inspectedCandidate.storeId != null ? inspectedCandidate.storeId : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>MD ID</th>
              <td>{{ inspectedCandidate.mdid != null ? inspectedCandidate.mdid : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Account ID</th>
              <td>{{ inspectedCandidate.accountId != null ? inspectedCandidate.accountId : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Personalnummer</th>
              <td>{{ inspectedCandidate.personnelId != null ? inspectedCandidate.personnelId : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="(inspectedCandidate.actionRequired
                && (isActionRequiredReason('missingdata', 'firstName')
                  || isActionRequiredReason('coreDataChanged')
                  || isActionRequiredReason('externalDataChanged', ['firstName', 'fn'])
                  || !inspectedCandidate.firstName))
                ? { color:'red' } : {}">Vorname</th>
              <td>{{ inspectedCandidate.firstName != null ? inspectedCandidate.firstName : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired
                && (isActionRequiredReason('missingdata', 'lastName') || isActionRequiredReason('coreDataChanged')
                || isActionRequiredReason('externalDataChanged', ['lastName', 'ln']) || !inspectedCandidate.lastName)
                ? { color:'red' } : {}">Nachname</th>
              <td>{{ inspectedCandidate.lastName != null ? inspectedCandidate.lastName : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired
                && (isActionRequiredReason('missingdata', 'dob') || isActionRequiredReason('coreDataChanged')
                || isActionRequiredReason('externalDataChanged', 'dob') || !inspectedCandidate.dob)
                ? { color:'red' } : {}">Geburtsdatum</th>
              <td>{{ inspectedCandidate.dob != null ? inspectedCandidate.dob : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired
                && (isActionRequiredReason('missingdata', 'socialsecurity') || isActionRequiredReason('coreDataChanged')
                || isActionRequiredReason('externalDataChanged', ['socialsecurity', 'ss']) || !inspectedCandidate.socialsecurity)
                ? { color:'red' } : {}">Sozialversicherungsnummer</th>
              <td>{{ inspectedCandidate.socialsecurity != null ? inspectedCandidate.socialsecurity : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Externer Mitarbeiter</th>
              <td>{{ (inspectedCandidate.external != null && inspectedCandidate.external ? "Ja" : "Nein") + " (" + inspectedCandidate.external + ")" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', 'gender')
              ? { color:'red' } : {}">Geschlecht</th>
              <td>{{ inspectedCandidate.gender != null ? genderArray[genderCandidateArray[inspectedCandidate.gender]] + " (" + inspectedCandidate.gender + ")" : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired &&
                (isActionRequiredReason('missingData', 'email') || isActionRequiredReason('inputdata','email')
                || isActionRequiredReason('externalDataChanged', 'email') || !inspectedCandidate.email)
                ? { color:'red' } : {}">E-Mail</th>
              <td>{{ inspectedCandidate.email != null ? inspectedCandidate.email : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['civilStatusId', 'csi'])
              ? { color:'red' } : {}">Familienstand</th>
              <td>{{ inspectedCandidate.civilStatusId != null ? civilStatusArray[inspectedCandidate.civilStatusId] + " (" + inspectedCandidate.civilStatusId + ")" : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['citizenTypeId', 'ctid'])
              ? { color:'red' } : {}">Bürgertyp</th>
              <td>{{ inspectedCandidate.citizenTypeId != null ? citizenTypeArray[inspectedCandidate.citizenTypeId] + " (" + inspectedCandidate.citizenTypeId + ")" : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['nationality', 'nat'])
              ? { color:'red' } : {}">Nationalität</th>
              <td>{{ inspectedCandidate.nationality != null ? countryArray[inspectedCandidate.nationality] + " (" + inspectedCandidate.nationality + ")" : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && (isActionRequiredReason('externalDataChanged', 'address') || !inspectedCandidate.address)
              ? { color:'red' } : {}">Adresse</th>
              <td>{{ inspectedCandidate.address != null ? inspectedCandidate.address : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && (isActionRequiredReason('externalDataChanged', ['postalCode', 'pc']) || !inspectedCandidate.postalCode)
              ? { color:'red' } : {}">PLZ</th>
              <td>{{ inspectedCandidate.postalCode != null ? inspectedCandidate.postalCode : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && (isActionRequiredReason('externalDataChanged', 'city') || !inspectedCandidate.city)
              ? { color:'red' } : {}">Stadt</th>
              <td>{{ inspectedCandidate.city != null ? inspectedCandidate.city : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', 'country')
              ? { color:'red' } : {}">Land</th>
              <td>{{ inspectedCandidate.country != null ? countryArray[inspectedCandidate.country] + " (" + inspectedCandidate.country + ")" : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['mobilephone', 'mp'])
              ? { color:'red' } : {}">Mobiltelefon</th>
              <td>{{ inspectedCandidate.mobilephone != null ? inspectedCandidate.mobilephone : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['homephone', 'hp'])
              ? { color:'red' } : {}">Haustelefon</th>
              <td>{{ inspectedCandidate.homephone != null ? inspectedCandidate.homephone : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['bankAccountHolder', 'bah'])
              ? { color:'red' } : {}">Kontoinhaber</th>
              <td>{{ inspectedCandidate.bankAccountHolder != null ? inspectedCandidate.bankAccountHolder : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', 'iban')
              ? { color:'red' } : {}">IBAN</th>
              <td>{{ inspectedCandidate.iban != null ? inspectedCandidate.iban : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', 'bic')
              ? { color:'red' } : {}">BIC</th>
              <td>{{ inspectedCandidate.bic != null ? inspectedCandidate.bic : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Sonstige Info</th>
              <td>{{ inspectedCandidate.miscInfo != null ? inspectedCandidate.miscInfo : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Erziehungsberechtigter Name</th>
              <td>{{ inspectedCandidate.guardianName != null ? inspectedCandidate.guardianName : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Erziehungsberechtigter Adresse</th>
              <td>{{ inspectedCandidate.guardianAddress != null ? inspectedCandidate.guardianAddress : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Erziehungsberechtigter Telefon</th>
              <td>{{ inspectedCandidate.guardianPhone != null ? inspectedCandidate.guardianPhone : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Erste Hilfe Kurs</th>
              <td>{{ inspectedCandidate.firstaidcourse != null ? inspectedCandidate.firstaidcourse : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Erste Hilfe Kurs Ablaufdatum</th>
              <td>{{ inspectedCandidate.faExpiry != null ? inspectedCandidate.faExpiry : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Führerschein</th>
              <td>{{ inspectedCandidate.driverslicense != null ? inspectedCandidate.driverslicense : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Führerschein Ablaufdatum</th>
              <td>{{ inspectedCandidate.dlExpiry != null ? inspectedCandidate.dlExpiry : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Schwanger</th>
              <td>{{ inspectedCandidate.pregnant != null ? inspectedCandidate.pregnant : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>50% Behinderung</th>
              <td>{{ inspectedCandidate.disability != null ? inspectedCandidate.disability : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['financialAccountingGroupId', 'fagid'])
              ? { color:'red' } : {}">FIBU-Kreis</th>
              <td>{{ inspectedCandidate.financialAccountingGroupNumber != null && fibuByNumber[inspectedCandidate.financialAccountingGroupNumber] != null
                ? fibuByNumber[inspectedCandidate.financialAccountingGroupNumber].name : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['allocationGroup', 'ag'])
              ? { color:'red' } : {}">Abrechnungskreis</th>
              <td>{{ inspectedCandidate.allocationGroup != null ? inspectedCandidate.allocationGroup : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['workEntryDate', 'wed'])
              ? { color:'red' } : {}">Ersteintrittsdatum</th>
              <td>{{ inspectedCandidate.workEntryDate != null ? inspectedCandidate.workEntryDate : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Vordienstzeit</th>
              <td>{{ inspectedCandidate.workExperience != null ? inspectedCandidate.workExperience : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['paymentType', 'pt'])
              ? { color:'red' } : {}">Zahlungstyp</th>
              <td>{{ inspectedCandidate.paymentType != null ? paymentTypeArray[inspectedCandidate.paymentType] + " (" + inspectedCandidate.paymentType + ")" : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['wageType', 'wt'])
              ? { color:'red' } : {}">Lohntyp</th>
              <td>{{ inspectedCandidate.wageType != null ? wageTypeArray[inspectedCandidate.wageType] + " (" + inspectedCandidate.wageType + ")" : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', 'payment')
              ? { color:'red' } : {}">Bezahlung</th>
              <td>{{ inspectedCandidate.payment != null ? inspectedCandidate.payment / 100.0 : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th>Vertrag Kommentar</th>
              <td>{{ inspectedCandidate.contractComment != null ? inspectedCandidate.contractComment : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['collectiveContractId', 'ccid'])
              ? { color:'red' } : {}">Kollektivvertrag ID</th>
              <td>{{ inspectedCandidate.collectiveContractId != null ? inspectedCandidate.collectiveContractId : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['jobName', 'jn'])
              ? { color:'red' } : {}">Beruf</th>
              <td>{{ inspectedCandidate.jobName != null ? inspectedCandidate.jobName : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['contractType', 'ct'])
              ? { color:'red' } : {}">Vertragstyp</th>
              <td>{{ inspectedCandidate.contractType != null ? contractTypeArray[inspectedCandidate.contractType] + " (" + inspectedCandidate.contractType + ")" : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['contractStart', 'cs'])
              ? { color:'red' } : {}">Vertragsbeginn</th>
              <td>{{ inspectedCandidate.contractStart != null ? inspectedCandidate.contractStart : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['contractEnd', 'ce'])
              ? { color:'red' } : {}">Vertragsende</th>
              <td>{{ inspectedCandidate.contractEnd != null ? inspectedCandidate.contractEnd : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['employeeCategory', 'ec'])
              ? { color:'red' } : {}">Mitarbeiter Kategorie</th>
              <td>{{ inspectedCandidate.employeeCategory != null ? employeeCategoryArray[inspectedCandidate.employeeCategory] + " (" + inspectedCandidate.employeeCategory + ")" : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['employmentType', 'et'])
              ? { color:'red' } : {}">Anstellungsart</th>
              <td>{{ inspectedCandidate.employmentType != null ? employmentTypeArray[inspectedCandidate.employmentType] + " (" + inspectedCandidate.employmentType + ")" : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['weekTimeRegular', 'wtr'])
              ? { color:'red' } : {}">Wochenstunden</th>
              <td>{{ inspectedCandidate.weekTimeRegular != null ? inspectedCandidate.weekTimeRegular / 60.0 : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['monthTimeRegular', 'mtr'])
              ? { color:'red' } : {}">Monatsstunden</th>
              <td>{{ inspectedCandidate.monthTimeRegular != null ? inspectedCandidate.monthTimeRegular / 60.0 : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['weekWorkDaysRegular', 'wwdr'])
              ? { color:'red' } : {}">Arbeitstage pro Woche</th>
              <td>{{ inspectedCandidate.weekWorkDaysRegular != null ? inspectedCandidate.weekWorkDaysRegular : "&nbsp;" }}</td>
            </tr>
            <tr>
              <th v-bind:style="inspectedCandidate.actionRequired && isActionRequiredReason('externalDataChanged', ['vacationTime', 'vt'])
              ? { color:'red' } : {}">Urlaubstage</th>
              <td>{{ inspectedCandidate.vacationTime != null ? inspectedCandidate.vacationTime : "&nbsp;" }}</td>
            </tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closeSingleCandidatesModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>

    <b-modal size="lg" ref="modal-external-data-changed" @close="closeExternalDataChangedModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">
            Datenänderung in {{ importJob.id != null ? importJob.method : "" }}!
          </div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <div class="description">
            Beim Import aus {{ importJob.id != null ? importJob.method : "" }} wurden Änderungen erkannt. <br/>
            Bitte prüfen und bestätigen Sie die geänderten Daten, um Sie in ML zu übernehmen. <br/> <br/>
          </div>
          <div>
            Zu bearbeitende MitarbeiterIn:
          </div>
          <div class="emp-core-data">
            <div v-if="profilePictureUrl != null">
              <img
                style="margin: 4px; width: 120px; height: 120px"
                crossorigin="anonymous"
                id="employee-picture-img"
                :src="profilePictureUrl"
              />
            </div>
            <div>
              Name: <b>{{ externalImportEmployee.firstName + " " + externalImportEmployee.lastName }}</b>
              <br>
              Geburtsdatum: <b>{{ formatDate(externalImportEmployee.dob) }}</b>
              <br>
              Sozialversicherungsnummer: <b>{{ externalImportEmployee.socialsecurity }}</b>
            </div>
          </div>
          <div>
            <br>Veränderte Daten:
          </div>
          <table style="width: 100%">
            <tr>
              <th>Feld</th>
              <th>Daten in ML</th>
              <th>Importierte Daten</th>
            </tr>
            <tr v-for="field in externalImportIssueFields" v-bind:key="field.field">
              <th>{{ field.field }}</th>
              <td>{{ field.employee }}</td>
              <td>{{ field.candidate }}</td>
            </tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="buttons">
          <div class="button ml" v-bind:class="{ disabled: loadingDownload || loadingExport }" @click="forceEmployeeUpdateFromCandidate(externalImportCandidate); closeExternalDataChangedModal()">
            <div class="label">Änderungen übernehmen</div>
          </div>
          <div class="button" v-bind:class="{ disabled: loadingDownload || loadingExport }" @click="skipEmployeeCandidate(); closeExternalDataChangedModal()">
            <div class="label">Änderungen ablehnen</div>
          </div>
          <div v-if="employeeSyncSetting && employeeSyncSetting.enableExportViaTransferService && employeeSyncSettingsData.enableExportViaTransferService
                    && !(employeeSyncSetting.enablePeriodicExport && employeeSyncSettingsData.enablePeriodicExport)"
               class="button" v-bind:class="{ disabled: loadingExport }" @click="triggerCsvExport(externalImportEmployee.id)">
            <div class="label">Änderungen ablehnen und ML Daten nach {{ importJob.id != null ? importJob.method : "" }} exportieren</div>
          </div>
          <div v-if="employeeSyncSetting && !(employeeSyncSetting.enableExportViaTransferService && employeeSyncSettingsData.enableExportViaTransferService)"
               class="button" v-bind:class="{ disabled: loadingDownload }" @click="downloadCSV(externalImportEmployee.id)">
            <div class="label">Änderungen ablehnen und ML Daten für {{ importJob.id != null ? importJob.method : "" }} herunterladen</div>
          </div>
        </div>

      </template>
    </b-modal>

    <b-modal size="lg" ref="modal-external-data-changed-matching" @close="closeExternalDataChangedMatchingModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">
            Mitarbeiter verknüpfen
          </div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <div class="description">
            Bei der zu verknüpfenden MitarbeiterIn wurden Änderungen erkannt. <br/>
            Bitte prüfen und bestätigen Sie die geänderten Daten, um Sie in ML zu übernehmen. <br/> <br/>
          </div>
          <div>
            Zu verknüpfende MitarbeiterIn:
          </div>
          <div class="emp-core-data">
            <div v-if="matchingProfilePictureUrl != null">
              <img
                style="margin: 4px; width: 120px; height: 120px"
                crossorigin="anonymous"
                id="employee-picture-img"
                :src="matchingProfilePictureUrl"
              />
            </div>
            <div>
              Name: <b>{{ matchingEmployee.firstName + " " + matchingEmployee.lastName }}</b>
              <br>
              Geburtsdatum: <b>{{ formatDate(matchingEmployee.dob) }}</b>
              <br>
              Sozialversicherungsnummer: <b>{{ matchingEmployee.socialsecurity }}</b>
            </div>
          </div>
          <div>
            <br>Veränderte Daten:
          </div>
          <table style="width: 100%">
            <tr>
              <th>Feld</th>
              <th>Daten in ML</th>
              <th>Importierte Daten</th>
            </tr>
            <tr v-for="field in matchingIssueFields" v-bind:key="field.field">
              <th>{{ field.field }}</th>
              <td>{{ field.employee }}</td>
              <td>{{ field.candidate }}</td>
            </tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="buttons">
          <div class="button ml" v-bind:class="{ disabled: loadingDownload || loadingExport }" @click="matchEmployeeWithCandidate(matchingCandidate, matchingEmployee.id); closeExternalDataChangedMatchingModal()">
            <div class="label">Mitarbeiter verknüpfen</div>
          </div>
          <div class="button" v-bind:class="{ disabled: loadingDownload || loadingExport }" @click="skipEmployeeCandidate(); closeExternalDataChangedMatchingModal()">
            <div class="label">Mitarbeiter nicht verknüpfen</div>
          </div>
          <div class="button" v-bind:class="{ disabled: loadingDownload || loadingExport }" @click="closeExternalDataChangedMatchingModal()">
            <div class="label">Abbrechen</div>
          </div>
        </div>

      </template>
    </b-modal>

  </div>

</template>

<script>
import VueEventBus from '../../vue-event-bus';
import {mapState, mapGetters} from 'vuex';
import Papa from "papaparse";

export default {
  components: {},
  name: 'employeeImportJobs',
  data() {
    return {
      loading: true,

      importJob: {
        id: null,
        method: null
      },
      externalImportCandidate: {},
      externalImportEmployee: {},
      externalImportIssueFields: [],
      profilePictureUrl: null,

      matchingCandidate: {},
      matchingEmployee: {},
      matchingIssueFields: [],
      matchingProfilePictureUrl: null,

      candidates: [],
      importCandidates: [],

      employeeCandidateDiffId: null,

      inspectedCandidate: {},
      inspectedCandidates: [],
      inspectedCandidatesJob: {},

      candidateActions: {},

      jobs: [],
      filteredJobs: [],
      jobsPerPage: 10,
      currentPage: 1,

      employees: [],
      employeeContracts: [],
      allowedContracts: [],

      filterDropdownOptions: [],

      employeeCategoryArray: [],
      employmentTypeArray: [],
      contractTypeArray: [],
      wageTypeArray: [],
      paymentTypeArray: [],
      countryArray: [],
      genderArray: [],
      genderCandidateArray: {
        "M": 1,
        "F": 2,
        "D": 3
      },
      citizenTypeArray: [],
      civilStatusArray: [],

      employeeSyncSetting: undefined,
      employeeSyncSettingsData: {
        enableExportViaTransferService: false,
        enablePeriodicExport: false,
      },

      loadingDownload: false,
      loadingExport: false,

      financialAccountingGroups: [],

      companyData: null,
      enableImport: false,
      softwareIds: {
        0: "BMD",
        1: "DPW",
        2: "DATEV über SOTEC",
      },
    }
  },
  created: function () {
    //console.log("CREATED");
    VueEventBus.$on("JobEmployeeImportEvent:jobCreated", this.jobCreated);
    VueEventBus.$on("JobEmployeeImportEvent:jobStarted", this.jobStarted);
    VueEventBus.$on("JobEmployeeImportEvent:jobEnded", this.jobEnded);
  },
  beforeDestroy: function () {
    VueEventBus.$off("JobEmployeeImportEvent:jobCreated", null);
    VueEventBus.$off("JobEmployeeImportEvent:jobStarted", null);
    VueEventBus.$off("JobEmployeeImportEvent:jobEnded", null);
  },
  mounted: function () {
    let view = this;
    this.LoadFilterDropdown();
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
    let url = "/enum/country";
    this.api.get(url).then((response) => {
      for (let x in response.data) {
        let elem = response.data[x];
        view.countryArray[elem.id] = elem.valueGER;
      }
    });

    url = "/enum/gender";
    this.api.get(url).then((response) => {
      for (let x in response.data) {
        let elem = response.data[x];
        view.genderArray[elem.id] = elem.valueGER;
      }
    });

    url = "/enum/citizentype";
    this.api.get(url).then((response) => {
      for (let x in response.data) {
        let elem = response.data[x];
        view.citizenTypeArray[elem.id] = elem.valueGER;
      }
    });

    url = "/enum/civilstatus";
    this.api.get(url).then((response) => {
      for (let x in response.data) {
        let elem = response.data[x];
        view.civilStatusArray[elem.id] = elem.valueGER;
      }
    });
  },
  computed: {
    ...mapGetters(['companyId', 'isSuperUser', 'uiDataMap']),
    dateDisplay: function () {
      return this.$helpers.getMomentTZ().format('YYYY-MM-DD');
    },
    headerButtons() {
      let view = this;
      let state = {
        buttons: [
          // BUTTONS WHILE NO JOB IS SELECTED
          {
            type: "block border-r",
            icon: "fa-refresh",
            show: view.importJob.id == null,
            click: () => {
              view.refresh();
            },
            tooltip: 'Daten neu laden'
          },
          {
            type: "block border-r",
            icon: "fa-cloud-upload-alt",
            show: view.importJob.id == null,
            click: () => {
              view.openImportFromMDConfirmationPopup();
            },
            tooltip: 'Lade Mitarbeiter von MD'
          },
          {
            type: "block border-r",
            icon: "fa-file-csv",
            show: view.importJob.id == null && view.enableImport,
            click: () => {
              view.openCSVImportPopup();
            },
            tooltip: "Mitarbeiter Daten mit " + view.software + " Export importieren",
            helperText:
              "Mit 'Mitarbeiter Daten mit " + view.software + " Export importieren' können sie die Daten mehrerer Mitarbeiter aus einer CSV Datei importieren. <br/> Wichtig dabei ist dass die Datei in UTF-8 enkodiert ist und die Felder mit dem Namen enthält, wie sie in Einstellungen > Mitarbeiter Sync definiert sind.",
            externalUrl:
              "https://meindienstplan.atlassian.net/servicedesk/customer/kb/view/2599256065",
          },

          // BUTTONS WHILE JOB IS SELECTED
          {
            type: "block border-r",
            icon: "fa-chevron-left",
            show: view.importJob.id != null,
            click: () => {
              view.clearSelection();
            },
            tooltip: 'Zurück'
          },
          {
            type: "block",
            icon: "fa-ban",
            show: view.importJob.id != null,
            click: () => {
              view.openSkipAllConfirmationPopup();
            },
            tooltip: 'Keine importieren'
          },
          {
            type: "block border-r",
            icon: "fa-magnifying-glass",
            show: view.importJob.id != null,
            click: () => {
              view.openAllCandidatesModal(view.importJob);
            },
            tooltip: 'Alle Kandidaten anzeigen'
          },
        ],
        helperLinks: [
          {
            title: "Import Jobs",
            helperText:
                "Auf dieser Seite können Sie Mitarbeiter aus verschiedenen System importieren. Für eine detailierte Beschreibung, klicken Sie auf 'Mehr erfahren'.",
            externalUrl:
                "https://meindienstplan.atlassian.net/servicedesk/customer/kb/view/2640084993",
            externalLabel: "Mehr erfahren",
            cancelLabel: "Danke",
            icon: "fa-rotate"
          },
          {
            title: "Support erhalten",
            helperText:
                "Wenn Sie auf Ihre Frage keine Antwort im Hilfecenter finden, können Sie uns eine Support Anfrage stellen. Klicken Sie dazu einfach auf 'Support kontaktieren', geben Sie Ihre E-Mail Adresse ein, schreiben Sie eine detaillierte Zusammenfassung Ihres Problems inkl. der Daten Ihres Stores, laden Sie etwaige Anhänge (Bilder, etc.) hoch und klicken Sie auf 'Senden'. Wir sehen uns Ihr Problem an und melden uns so schnell wie möglich.",
            externalUrl:
                "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/group/11",
            externalLabel: "Support kontaktieren",
            cancelLabel: "Schließen",
          }
        ]
      };
      return state;
    },
    sideButtons() {
      let view = this;

      let state = {
        buttons: [],
      };
      state.buttons.push({
        type: "block",
        icon: "fa-eye",
        tooltip: "Anzeige Filter",
        click: ($event) => {
          view.OpenFilterDropdown($event);
        },
      });
      return state;
    },
    software() {
      let view = this;
      if (view.companyData != null) {
        return view.softwareIds[view.companyData.softwareId];
      } else {
        return "Lohnverrechnungstool";
      }
    },
    paginatedFilteredJobs() {
      let view = this;

      let indexStart = (view.currentPage - 1) * view.jobsPerPage;
      let indexEnd = indexStart + view.jobsPerPage;

      return Object.values(view.filteredJobs).slice(indexStart, indexEnd);
    },
    pages() {
      let view = this;
      let pages = Math.ceil(Object.values(view.filteredJobs).length / view.jobsPerPage);
      if (pages === 0) {
        pages = 1;
      }
      return pages;
    },
    formattedCurrentPage() {
      let view = this;
      let formattedPage = "";
      for (let i = 1; this.pages / i > 10; i*=10) {
        if (view.currentPage < i*10) {
          formattedPage += '\xa0\xa0';
        }
      }
      return formattedPage + view.currentPage;
    },
    fibuByNumber() {
      let fibuByNumber = {};
      Object.values(this.financialAccountingGroups).forEach(fibu => {
        fibuByNumber[fibu.number] = fibu;
      });
      return fibuByNumber;
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
    filterDropdownOptions: {
      handler (val, oldVal) {
        this.filterJobs();
      },
      deep: true
    }
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      console.log("REFRESH");
      let view = this;

      view.currentPage = 1;
      view.loading = true;
      view.jobs = [];
      view.filteredJobs = [];
      view.importJob = {
        id: null,
        method: null
      };
      view.externalImportCandidate = {};
      view.employees = [];
      view.candidates = [];
      view.candidateActions = {};
      view.inspectedCandidate = {};
      view.inspectedCandidates = [];

      view.employeeSyncSetting = undefined;
      view.employeeSyncSettingsData = {
        enableExportViaTransferService: false,
          enablePeriodicExport: false,
      };

      view.$helpers
        .GetCompanySettings(force)
        .then((response) => {
          view.updateCompanySettings(response);
          view.getEmployeeSyncSettings()
            .then((settings) => {
              view.employeeSyncSetting = settings.find(res => {
                return res.softwareId === view.companyData.softwareId
              });
              if (view.employeeSyncSetting !== undefined) {
                view.getEmployeeSyncSettingsData()
                  .then((data) => {
                    view.employeeSyncSettingsData = data;
                    view.enableImport = true;
                  });
              }
            });
        });
      view.loadEmployeeImportJobs();
      view.selectImportJob(view.importJob);
      view.setupUIDataMaps();

      // load following data in the background
      view.loadEmployees();
      view.loadEmployeeContracts();
      view.loadFinancialAccountingGroups();
      view.$helpers.GetStoresSettings()
        .then(response => {
          response.data.forEach(store => {
            this.loadAllowedContracts(store.id);
          });
        });
    },
    clearSelection: function () {
      let view = this;
      view.importJob = {
        id: null,
        method: null
      };
      view.externalImportCandidate = {};
      view.inspectedCandidates = [];
    },

    loadEmployees: function (force = false, employeeIdToSelect = null) {
      let view = this;
      this.api
        .get("/sec/employee/coredata")
        .then((response) => {
          let empls = response.data.data;
          this.employees = empls;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadEmployeeContracts() {
      let view = this;
      let url = '/api/sec/proxy/md/employee/contracts';

      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/proxy/md/employee/contracts');

      this.axios({
        method: 'get',
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url
      }).then(response => {
        if (response.data !== undefined) {
          response.data.sort((a, b) => {
            if (a.from > b.from) {
              return 1;
            } else if (a.from < b.from) {
              return -1;
            }
            return 0;
          });
          // only save the latest contract per employee/store
          response.data.forEach(entry => {
            if (view.employeeContracts[entry.remoteId] === undefined) {
              view.employeeContracts[entry.remoteId] = [];
            }
            view.employeeContracts[entry.remoteId][entry.storeId] = entry;
          });
        }
      });
    },
    loadAllowedContracts(storeId) {
      let view = this;
      let url = '/api/sec/proxy/md/allowedContracts';
      view.allowedContracts = [];

      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/proxy/md/allowedContracts');
      url = this.$helpers.appendParam("storeId", storeId, url, '/api/sec/proxy/md/allowedContracts');

      this.axios({
        method: 'get',
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url
      }).then(response => {
        if (response.data !== undefined) {
          view.allowedContracts[storeId] = [];
          response.data.forEach(allowedContract => {
            view.allowedContracts[storeId][allowedContract.id] = allowedContract;
          });
        }
      });
    },

    updateCompanySettings: function (response) {
      let view = this;

      if (response.data.length === 1) {
        view.companyData = response.data[0];
      }
    },

    jobCreated: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB CREATED EVENT GOT TRIGGERED");
        view.refresh();
      }, 50);
    },
    jobStarted: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB STARTED EVENT GOT TRIGGERED");
        view.refresh();
      }, 100);
    },
    jobEnded: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB ENDED EVENT GOT TRIGGERED");
        view.refresh();
      }, 200);
    },

    selectImportJob: function (job) {
      this.importJob = job;
      if (job != null) {
        this.loadEmployeeImportCandidates();
      } else {
        //this.candidates = [];
        this.employeeCandidateDiffId = null;
      }
    },
    checkCandidateAction: function (candidate) {
      this.employeeCandidateDiffId = candidate.id;
      if (candidate.actionRequiredReason === "internalEmployeeNotFound") {
        this.createEmployee(candidate);
      } else if (candidate.actionRequiredReason === "coreDataChanged") { // import from MD / MDCORE has differences
        this.loadEmployeeCandidateDiff()
          .then((response) => {
            this.openCoreDataIssuePopup(candidate, response.data);
          });
      } else if (candidate.actionRequiredReason &&
        (candidate.actionRequiredReason.substring(0,9) === "inputdata" || candidate.actionRequiredReason.substring(0,11) === "missingdata")) {
        this.openInputIssuePopup(candidate);
      } else if (candidate.actionRequiredReason && candidate.actionRequiredReason.substring(0,19) === "externalDataChanged") {
        this.openExternalDataChangedModal(candidate);
      } else {
        console.log("Unknown issue: " + candidate.actionRequiredReason);
        this.openUnknownIssuePopup(candidate);
      }
    },
    isActionRequiredReason(reason, data = null) {
      if (this.inspectedCandidate.actionRequiredReason !== null &&
          this.inspectedCandidate.actionRequiredReason !== undefined &&
          this.inspectedCandidate.actionRequiredReason.substring(0, reason.length) === reason) {
        if (data) {
          let reasonArray = this.inspectedCandidate.actionRequiredReason.split(":");
          if (reasonArray.length > 1) {
            let dataArray = reasonArray[1].split(",");
            if (Array.isArray(data)) {
              let val = false;
              data.forEach((elem) => {
                if (dataArray.includes(elem)) {
                  val = true;
                }
              });
              return val;
            }
            if (dataArray.includes(data)) {
              return true;
            }
            return false;
          }
          return false;
        }
        return true;
      }
      return false;
    },

    openImportFromMDConfirmationPopup() {
      let view = this;

      let entries = [];
      let description = "Möchten Sie wirklich Mitarbeiter von Mein Dienstplan importieren?";

      return new Promise((resolve, accept) => {
        view.$helpers.OpenPopup({
          type: "datafill",
          componentState: {
            entries: entries,
            description: description,
            positiveButtonLabel: "Importieren!",
            selectCallback: function (item) {
              view.startImportFromExternalSystem("md");
            },
          },
          header: "Mitarbeiter Import aus MD",
          centered: true,
          expanded: true,
        });
      });
    },
    startImportFromExternalSystem: function (mode) {
      let view = this;
      let url = '/api/employee/importJob';
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, url);

      let payload = {
        jobMethod: mode
      }


      this.axios({
        method: 'post',
        headers: {'AUTHORIZATION': "Bearer " + this.$store.state.jwt},
        url: url,
        data: payload
      }).then(response => {
        console.log(response.data.data);
        view.loadEmployeeImportJobs();
      }).catch(function (error) {
        console.log(error);
      })

    },

    loadEmployeeImportJobs: function (force = false) {
      let view = this;
      let baseUrl = '/api/employee/importJob';
      let url = this.$helpers.appendParam("companyId", this.$store.state.companyId, baseUrl, baseUrl);

      this.axios({
        method: 'get',
        headers: {'AUTHORIZATION': "Bearer " + this.$store.state.jwt},
        url: url,
      }).then(response => {
        // load and show first page of data
        view.jobs = response.data.data;
        this.filterJobs();

        Object.values(view.jobs).forEach((elem) => {
          elem.jobStartedConverted = elem.jobStarted != null ? view.$helpers.convertUTCDateStringTZ(elem.jobStarted, 'DD.MM.YYYY HH:mm:ss') : null;
          elem.jobEndedConverted = elem.jobEnded != null ? view.$helpers.convertUTCDateStringTZ(elem.jobEnded, 'DD.MM.YYYY HH:mm:ss') : null;
        });
        view.loading = false;

        // call other pages
        if (response.data.last_page > 1) {
          console.log(response.data.current_page);
          console.log(response.data.last_page);
          view.loadEmployeeImportJobsPages(baseUrl, response.data.current_page + 1, response.data.last_page)
          .then((response) => {
            this.filterJobs();

            Object.values(view.jobs).forEach((elem) => {
              elem.jobStartedConverted = elem.jobStarted != null ? view.$helpers.convertUTCDateStringTZ(elem.jobStarted, 'DD.MM.YYYY HH:mm:ss') : null;
              elem.jobEndedConverted = elem.jobEnded != null ? view.$helpers.convertUTCDateStringTZ(elem.jobEnded, 'DD.MM.YYYY HH:mm:ss') : null;
            });
          });
        }

      }).catch(function (error) {
        console.log(error);
      })
    },
    loadEmployeeImportJobsPages(baseUrl, page, lastPage) {
      let view = this;
      if (page <= lastPage) {
        let url = this.$helpers.appendParam("companyId", view.$store.state.companyId, baseUrl, baseUrl);
        url = this.$helpers.appendParam("page", page, url, baseUrl);

        return view.axios({
          method: 'get',
          headers: {'AUTHORIZATION': "Bearer " + this.$store.state.jwt},
          url: url,
        }).then(response => {
          view.jobs = view.jobs.concat(response.data.data);
          return view.loadEmployeeImportJobsPages(baseUrl, page + 1, lastPage);
        }).catch(function (error) {
          console.log(error);
        });
      }


    },
    loadEmployeeImportCandidates: function (importjob = null, force = false) {
      let view = this;
      view.inspectedCandidates = [];

      if (importjob == null) {
        importjob = this.importJob;
      }
      if (force || !view.candidates[importjob.id]) {
        view.candidates[importjob.id] = [];
      }

      if ((importjob.id != null) && (view.candidates[importjob.id].length == 0)) {
        console.log("loading candidates");
        let baseUrl = '/api/employee/candidate/all';
        let url = baseUrl;
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
        url = this.$helpers.appendParam("employeeImportJobId", importjob.id, url, baseUrl);

        this.axios({
          method: 'get',
          headers: {'AUTHORIZATION': "Bearer " + this.$store.state.jwt},
          url: url,
        }).then(response => {
          let candidates = response.data.data.sort((a,b) => {
            // sort by lastName, firstName, remoteId, contractStart
            if (a.lastName < b.lastName) {
              return -1;
            } else if (a.lastName > b.lastName) {
              return 1;
            } else if (a.firstName < b.firstName) {
              return -1;
            } else if (a.firstName > b.firstName) {
              return 1;
            } else if (a.remoteId < b.remoteId) {
              return -1;
            } else if (a.remoteId > b.remoteId) {
              return 1;
            } else if (a.contractStart > b.contractStart) {
              return 1;
            } else {
              return -1;
            }
        });
          view.candidates[importjob.id] = candidates;
          view.inspectedCandidates = candidates;

          view.loadEmployeeImportCandidateActionsTaken(importjob.id);

        }).catch(function (error) {
          console.log(error);
        })
      } else {
        view.inspectedCandidates = view.candidates[importjob.id];
      }
    },
    loadEmployeeImportCandidateActionsTaken: function (importJobId) {
      let view = this;
      let baseUrl = '/api/employee/candidate/actions';
      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
      url = this.$helpers.appendParam("employeeImportJobId", importJobId, url, baseUrl);

      this.axios({
        method: 'get',
        headers: {'AUTHORIZATION': "Bearer " + this.$store.state.jwt},
        url: url,
      }).then(response => {
        console.log(response);
        response.data.forEach(action => {
          action.actionTakenAtConverted = action.actionTakenAt != null ? view.$helpers.convertUTCDateStringTZ(action.actionTakenAt, 'DD.MM.YYYY HH:mm:ss') : null;
          view.candidateActions[action.employeeCandidateId] = action;
        });
      }).catch(function (error) {
        console.log(error);
      });
    },
    loadEmployeeCandidateDiff: function (force = false) {
      let view = this;
      let baseUrl = '/api/employee/candidate/diff';
      let url = '/api/employee/candidate/diff';
      url = this.$helpers.appendParam("employeeCandidateId", this.employeeCandidateDiffId, url, baseUrl);
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
      url = this.$helpers.appendParam("employeeImportJobId", this.importJob.id, url, baseUrl);

      return this.axios({
        method: 'get',
        headers: {'AUTHORIZATION': "Bearer " + this.$store.state.jwt},
        url: url,
      }).catch(function (error) {
        console.log(error);
      })
    },
    skipEmployeeCandidate: function (force = false) {
      let view = this;
      let url = '/api/employee/candidate/skip';
      let payload = {
        employeeCandidateId: this.employeeCandidateDiffId,
        companyId: this.$store.state.companyId,
        employeeImportJobId: this.importJob.id,
      };

      this.axios({
        method: 'put',
        headers: {'AUTHORIZATION': "Bearer " + this.$store.state.jwt},
        url: url,
        data: payload
      }).then(response => {
        Object.values(view.candidates[view.importJob.id]).forEach((elem, index) => {
          if (this.employeeCandidateDiffId === elem.id) {
            elem.actionRequired = 0;
          }
        });
        const count = view.candidates[view.importJob.id].filter(elem => {
          if (elem.actionRequired === 1) {
            return true;
          }
          return false;
        }).length;
        if (count === 0) {
          view.refresh();
        }
      }).catch(function (error) {
        console.log(error);
      })
    },
    skipAllEmployeeCandidates: function (force = false) {
      let view = this;
      let url = '/api/employee/candidate/skip/all';
      let payload = {
        companyId: this.$store.state.companyId,
        employeeImportJobId: this.importJob.id,
      };

      this.axios({
        method: 'put',
        headers: {'AUTHORIZATION': "Bearer " + this.$store.state.jwt},
        url: url,
        data: payload
      }).then(response => {
        this.refresh();
      }).catch(function (error) {
        console.log(error);
      })
    },
    openSkipAllConfirmationPopup() {
      let view = this;

      let entries = [];
      let description = "Möchten Sie wirklich keinen Eintrag aus diesem Import übernehmen?";

      return new Promise((resolve, accept) => {
        view.$helpers.OpenPopup({
          type: "datafill",
          componentState: {
            entries: entries,
            description: description,
            selectCallback: function (item) {
              view.skipAllEmployeeCandidates();
            },
          },
          header: "Keine importieren",
          centered: true,
          expanded: true,
        });
      });
    },

    createEmployee(candidate) {
      this.$helpers.log(this, "createEmployee", candidate);
      this.getCandidates(candidate).then((response) => {
        let candidatesResponse = response.data.data;
        let selectableItems = this.transformCandidateResult(candidatesResponse);
        if (selectableItems.length > 0) {
          this.openCandidateChooserPopup(candidate, selectableItems).then((remoteId) => {
            if (remoteId === 0) {
              // console.log("mitabeiter nicht importieren");
              this.skipEmployeeCandidate();
            } else if (remoteId != null) {
              // console.log("mitabeiter matchen: " + remoteId);
              this.checkEmployeeDataForCandidateMatching(candidate, remoteId);
            } else {
              // console.log("mitabeiter neu anlegen");
              this.saveNewEmployee(candidate);
            }
          });
        } else {
          this.openSingleCandidateCreatorPopup(candidate).then((remoteId) => {
            if (remoteId === 0) {
              // console.log("mitabeiter nicht importieren");
              this.skipEmployeeCandidate();
            } else {
              // console.log("mitabeiter neu anlegen");
              this.saveNewEmployee(candidate);
            }
          });
        }
      }).catch((error) => {
        // this should happen, when there is an error during getting candidates
        this.openSingleCandidateCreatorPopup(candidate).then((remoteId) => {
          if (remoteId === 0) {
            // console.log("mitabeiter nicht importieren");
            this.skipEmployeeCandidate();
          } else {
            // console.log("mitabeiter neu anlegen");
            this.saveNewEmployee(candidate);
          }
        });
      });
    },
    saveNewEmployee(candidate) {
      let view = this;
      let payload = {
        candidateId: candidate.id
      };

      let url = "/sec/employee/candidate/create";
      this.api.post(url, payload).then((response) => {
        console.log(response);
        if (response.status == 200) {
          view.$helpers.success("Gespeichert", "Mitarbeiter gespeichert!");
          Object.values(view.candidates[view.importJob.id]).forEach((elem, index) => {
            if (this.employeeCandidateDiffId === elem.id) {
              elem.actionRequired = 0;
            }
          });
        }
      }).catch((error) => {
        console.log(error.response.data.message);
        if (error.response.data.message.substring(0,16) === "inputdata:email:") {
          view.$helpers.error("Fehler", "E-Mail Adresse " + error.response.data.message.substring(16) + " existiert bereits.");
        } else if (error.response.data.message.substring(0,12) === "missingdata:") {
          view.$helpers.error("Fehler", "Mitarbeiter konnte nicht angelegt werden. Es fehlen notwendige Daten.");
        } else {
          view.$helpers.error("Fehler", "Mitarbeiter konnte nicht angelegt werden.");
        }
      });
    },
    checkEmployeeDataForCandidateMatching(candidate, remoteId) {
      let view = this;
      // console.log("checkEmployeeDataForCandidateMatching");

      view.matchingIssueFields = [];
      view.matchingCandidate = candidate;

      let employee = view.employees.find(emp => emp.id === remoteId);
      view.matchingEmployee = employee;
      let contract = view.employeeContracts[employee.id] !== undefined ? view.employeeContracts[employee.id][candidate.storeId] : undefined;

      view.matchingProfilePictureUrl = view.GetProfilePictureUrl(employee, 'large', true);


      if ((view.matchingEmployee.firstName ?? "") !== (view.matchingCandidate.firstName ?? "")) {
        view.matchingIssueFields.push({
          field: "Vorname",
          employee: view.matchingEmployee.firstName,
          candidate: view.matchingCandidate.firstName
        });
      }
      if ((view.matchingEmployee.lastName ?? "") !== (view.matchingCandidate.lastName ?? "")) {
        view.matchingIssueFields.push({
          field: "Nachname",
          employee: view.matchingEmployee.lastName,
          candidate: view.matchingCandidate.lastName
        });
      }
      if ((view.matchingEmployee.dob ?? "") !== (view.matchingCandidate.dob ?? "")) {
        view.matchingIssueFields.push({
          field: "Geburtsdatum",
          employee: view.matchingEmployee.dob,
          candidate: view.matchingCandidate.dob
        });
      }
      if ((view.matchingEmployee.socialsecurity ?? "") !== (view.matchingCandidate.socialsecurity ?? "")) {
        view.matchingIssueFields.push({
          field: "Sozialversicherungsnummer",
          employee: view.matchingEmployee.socialsecurity,
          candidate: view.matchingCandidate.socialsecurity
        });
      }
      if (view.genderArray[view.matchingEmployee.genderId] !== (view.genderCandidateArray.hasOwnProperty(view.matchingCandidate.gender) ?
                view.genderArray[view.genderCandidateArray[view.matchingCandidate.gender]] :
                view.genderArray[0])) {
        view.matchingIssueFields.push({
          field: "Geschlecht",
          employee: view.genderArray[view.matchingEmployee.genderId],
          candidate: view.genderCandidateArray.hasOwnProperty(view.matchingCandidate.gender) ?
                view.genderArray[view.genderCandidateArray[view.matchingCandidate.gender]] :
                view.genderArray[0]
        });
      }
      if ((view.matchingEmployee.email ?? "") !== (view.matchingCandidate.email ?? "")) {
        view.matchingIssueFields.push({
          field: "Email",
          employee: view.matchingEmployee.email,
          candidate: view.matchingCandidate.email
        });
      }
      if (view.civilStatusArray[view.matchingEmployee.civilStatusId] !== view.civilStatusArray[view.matchingCandidate.civilStatusId]) {
        view.matchingIssueFields.push({
          field: "Familienstand",
          employee: view.civilStatusArray[view.matchingEmployee.civilStatusId],
          candidate: view.civilStatusArray[view.matchingCandidate.civilStatusId]
        });
      }
      if (view.citizenTypeArray[view.matchingEmployee.citizenTypeId] !== view.citizenTypeArray[view.matchingCandidate.citizenTypeId]) {
        view.matchingIssueFields.push({
          field: "Bürgertyp",
          employee: view.citizenTypeArray[view.matchingEmployee.citizenTypeId],
          candidate: view.citizenTypeArray[view.matchingCandidate.citizenTypeId]
        });
      }
      if (view.countryArray[view.matchingEmployee.citizenshipId] !== view.countryArray[view.matchingCandidate.nationality]) {
        view.matchingIssueFields.push({
          field: "Nationalität",
          employee: view.countryArray[view.matchingEmployee.citizenshipId],
          candidate: view.countryArray[view.matchingCandidate.nationality]
        });
      }
      if ((view.matchingEmployee.address ?? "") !== (view.matchingCandidate.address ?? "")) {
        view.matchingIssueFields.push({
          field: "Adresse",
          employee: view.matchingEmployee.address,
          candidate: view.matchingCandidate.address
        });
      }
      if ((view.matchingEmployee.postalCode ?? "") !== (view.matchingCandidate.postalCode ?? "")) {
        view.matchingIssueFields.push({
          field: "PLZ",
          employee: view.matchingEmployee.postalCode,
          candidate: view.matchingCandidate.postalCode
        });
      }
      if ((view.matchingEmployee.city ?? "") !== (view.matchingCandidate.city ?? "")) {
        view.matchingIssueFields.push({
          field: "Stadt",
          employee: view.matchingEmployee.city,
          candidate: view.matchingCandidate.city
        });
      }
      if (view.countryArray[view.matchingEmployee.countryId] !== view.countryArray[view.matchingCandidate.country ?? 0]) {
        view.matchingIssueFields.push({
          field: "Land",
          employee: view.countryArray[view.matchingEmployee.countryId],
          candidate: view.countryArray[view.matchingCandidate.country]
        });
      }
      if ((view.matchingEmployee.mobilephone ?? "") !== (view.matchingCandidate.mobilephone ?? "")) {
        view.matchingIssueFields.push({
          field: "Mobiltelefon",
          employee: view.matchingEmployee.mobilephone,
          candidate: view.matchingCandidate.mobilephone
        });
      }
      if ((view.matchingEmployee.homephone ?? "") !== (view.matchingCandidate.homephone ?? "")) {
        view.matchingIssueFields.push({
          field: "Haustelefon",
          employee: view.matchingEmployee.homephone,
          candidate: view.matchingCandidate.homephone
        });
      }
      if ((view.matchingEmployee.bankAccountHolder ?? "") !== (view.matchingCandidate.bankAccountHolder ?? "")) {
        view.matchingIssueFields.push({
          field: "Kontoinhaber",
          employee: view.matchingEmployee.bankAccountHolder,
          candidate: view.matchingCandidate.bankAccountHolder
        });
      }
      if ((view.matchingEmployee.iban ?? "") !== (view.matchingCandidate.iban ?? "")) {
        view.matchingIssueFields.push({
          field: "IBAN",
          employee: view.matchingEmployee.iban,
          candidate: view.matchingCandidate.iban
        });
      }
      if ((view.matchingEmployee.bic ?? "") !== (view.matchingCandidate.bic ?? "")) {
        view.matchingIssueFields.push({
          field: "BIC",
          employee: view.matchingEmployee.bic,
          candidate: view.matchingCandidate.bic
        });
      }
      if (((view.matchingEmployee.financialAccountingGroupId != null && view.financialAccountingGroups[view.matchingEmployee.financialAccountingGroupId])
          ? view.financialAccountingGroups[view.matchingEmployee.financialAccountingGroupId].name : null)
        !==
        ((view.matchingCandidate.financialAccountingGroupNumber != null && view.fibuByNumber[view.matchingCandidate.financialAccountingGroupNumber])
          ? view.fibuByNumber[view.matchingCandidate.financialAccountingGroupNumber].name : null)) {
        view.matchingIssueFields.push({
          field: "FIBU-Kreis",
          employee: (view.matchingEmployee.financialAccountingGroupId != null && view.financialAccountingGroups[view.matchingEmployee.financialAccountingGroupId])
            ? view.financialAccountingGroups[view.matchingEmployee.financialAccountingGroupId].name : null,
          candidate: (view.matchingCandidate.financialAccountingGroupNumber != null && view.fibuByNumber[view.matchingCandidate.financialAccountingGroupNumber])
            ? view.fibuByNumber[view.matchingCandidate.financialAccountingGroupNumber].name : null
        });
      }
      if ((view.matchingEmployee.allocationGroup ?? "") !== (view.matchingCandidate.allocationGroup ?? "")) {
        view.matchingIssueFields.push({
          field: "Abrechnungskreis",
          employee: view.matchingEmployee.allocationGroup,
          candidate: view.matchingCandidate.allocationGroup
        });
      }
      if (this.formatDate(view.matchingEmployee.workEntryDate) !== this.formatDate(view.matchingCandidate.workEntryDate)) {
        view.matchingIssueFields.push({
          field: "Ersteintrittsdatum",
          employee: this.formatDate(view.matchingEmployee.workEntryDate),
          candidate: this.formatDate(view.matchingCandidate.workEntryDate)
        });
      }
      // if ((view.importJob.method == "BMD" ? view.matchingEmployee.bmdId : "") !== view.matchingCandidate.externalId) {
      //   view.matchingIssueFields.push({
      //     field: "Externe ID",
      //     employee: view.importJob.method == "BMD" ? view.matchingEmployee.bmdId : "",
      //     candidate: view.matchingCandidate.externalId
      //   });
      // }
      if ((view.matchingEmployee.mdPersonnelId ?? "") !== (view.matchingCandidate.personnelId ?? "")) {
        view.matchingIssueFields.push({
          field: "Personalnummer",
          employee: view.matchingEmployee.mdPersonnelId,
          candidate: view.matchingCandidate.personnelId
        });
      }
      if ((contract != null ? view.paymentTypeArray[contract.paymentType] : "") !== view.paymentTypeArray[view.matchingCandidate.paymentType]) {
        view.matchingIssueFields.push({
          field: "Zahlungstyp",
          employee: contract != null ? view.paymentTypeArray[contract.paymentType] : "",
          candidate: view.paymentTypeArray[view.matchingCandidate.paymentType]
        });
      }
      if ((contract != null ? view.wageTypeArray[contract.wageType] : "") !== view.wageTypeArray[view.matchingCandidate.wageType]) {
        view.matchingIssueFields.push({
          field: "Lohntyp",
          employee: contract != null ? view.wageTypeArray[contract.wageType] : "",
          candidate: view.wageTypeArray[view.matchingCandidate.wageType]
        });
      }
      if ((contract != null ? contract.payment / 100.0 : "") !== view.matchingCandidate.payment / 100.0) {
        view.matchingIssueFields.push({
          field: "Bezahlung",
          employee: contract != null ? contract.payment / 100.0 : "",
          candidate: view.matchingCandidate.payment / 100.0
        });
      }
      if ((contract != null ? view.allowedContracts[contract.storeId][contract.collectiveContractId].collectiveContractId : "") !== view.matchingCandidate.collectiveContractId) {
        view.matchingIssueFields.push({
          field: "Kollektivvertrag ID",
          employee: contract != null ? view.allowedContracts[contract.storeId][contract.collectiveContractId].collectiveContractId : "",
          candidate: view.matchingCandidate.collectiveContractId
        });
      }
      if (contract != null && contract.jobName ? contract.jobName : "" !== (view.matchingCandidate.jobName ?? "")) {
        view.matchingIssueFields.push({
          field: "Beruf",
          employee: contract != null ? contract.jobName : "",
          candidate: view.matchingCandidate.jobName
        });
      }
      if ((contract != null ? this.contractTypeArray[contract.contractType] : "") !== this.contractTypeArray[view.matchingCandidate.contractType]) {
        view.matchingIssueFields.push({
          field: "Vertragstyp",
          employee: contract != null ? this.contractTypeArray[contract.contractType] : "",
          candidate: this.contractTypeArray[view.matchingCandidate.contractType]
        });
      }
      if ((contract != null ? this.formatDate(contract.from) : "") !== this.formatDate(view.matchingCandidate.contractStart)) {
        view.matchingIssueFields.push({
          field: "Vertragsbeginn",
          employee: contract != null ? this.formatDate(contract.from) : "",
          candidate: this.formatDate(view.matchingCandidate.contractStart)
        });
      }
      if ((contract != null ? this.formatDate(contract.to) : "") !== this.formatDate(view.matchingCandidate.contractEnd)) {
        view.matchingIssueFields.push({
          field: "Vertragsende",
          employee: contract != null ? this.formatDate(contract.to) : "",
          candidate: this.formatDate(view.matchingCandidate.contractEnd)
        });
      }
      if ((contract != null ? this.employeeCategoryArray[contract.employeeCategory] : "") !== this.employeeCategoryArray[view.matchingCandidate.employeeCategory]) {
        view.matchingIssueFields.push({
          field: "Mitarbeiter Kategorie",
          employee: contract != null ? this.employeeCategoryArray[contract.employeeCategory] : "",
          candidate: this.employeeCategoryArray[view.matchingCandidate.employeeCategory]
        });
      }
      if ((contract != null ? this.employmentTypeArray[contract.employmentType] : "") !== this.employmentTypeArray[view.matchingCandidate.employmentType]) {
        view.matchingIssueFields.push({
          field: "Anstellungsart",
          employee: contract != null ? this.employmentTypeArray[contract.employmentType] : "",
          candidate: this.employmentTypeArray[view.matchingCandidate.employmentType]
        });
      }
      if ((contract != null ? contract.weekTimeRegular / 60.0 : "") !== view.matchingCandidate.weekTimeRegular / 60.0) {
        view.matchingIssueFields.push({
          field: "Wochenstunden",
          employee: contract != null ? contract.weekTimeRegular / 60.0 : "",
          candidate: view.matchingCandidate.weekTimeRegular / 60.0
        });
      }
      if ((contract != null ? (Math.round((view.allowedContracts[contract.storeId][contract.collectiveContractId].monthTimeRegular / 60.0 + Number.EPSILON) * 100) / 100) : "")
        !== (view.matchingCandidate.monthTimeRegular ? (Math.round((view.matchingCandidate.monthTimeRegular / 60.0 + Number.EPSILON) * 100) / 100) : "")) {
        view.matchingIssueFields.push({
          field: "Monatsstunden",
          employee: contract != null ? (Math.round((view.allowedContracts[contract.storeId][contract.collectiveContractId].monthTimeRegular / 60.0 + Number.EPSILON) * 100) / 100) : "",
          candidate: view.matchingCandidate.monthTimeRegular ? (Math.round((view.matchingCandidate.monthTimeRegular / 60.0 + Number.EPSILON) * 100) / 100) : ""
        });
      }
      if ((contract != null ? contract.weekWorkDaysRegular : "") !== view.matchingCandidate.weekWorkDaysRegular) {
        view.matchingIssueFields.push({
          field: "Arbeitstage pro Woche",
          employee: contract != null ? contract.weekWorkDaysRegular : "",
          candidate: view.matchingCandidate.weekWorkDaysRegular
        });
      }
      if ((contract != null ? contract.vacationTime / 60 / 24 : "") !== view.matchingCandidate.vacationTime) {
        view.matchingIssueFields.push({
          field: "Urlaubstage",
          employee: contract != null ? contract.vacationTime / 60 / 24 : "",
          candidate: view.matchingCandidate.vacationTime
        });
      }

      if (view.matchingIssueFields.length > 0) {
        this.openExternalDataChangedMatchingModal();
      } else {
        this.matchEmployeeWithCandidate(candidate, remoteId);
      }
    },
    matchEmployeeWithCandidate(candidate, remoteId) {
      let view = this;
      let payload = {
        candidateId: candidate.id,
        remoteId: remoteId
      };

      let url = "/sec/employee/candidate/match";
      this.api.post(url, payload).then((response) => {
        console.log(response);
        if (response.status == 200) {
          view.$helpers.success("Gespeichert", "Mitarbeiter gespeichert!");
          Object.values(view.candidates[view.importJob.id]).forEach((elem, index) => {
            if (this.employeeCandidateDiffId === elem.id) {
              elem.actionRequired = 0;
            }
          });
          const count = view.candidates[view.importJob.id].filter(elem => {
            if (elem.actionRequired === 1) {
              return true;
            }
            return false;
          }).length;
          if (count === 0) {
            view.refresh();
          }
        }
      }).catch((error) => {
        if (error.response.data.data.email) {
          view.$helpers.error("Fehler", "E-Mail Adresse " + error.response.data.data.email + " existiert bereits für einen anderen Mitarbeiter.");
        } else {
          view.$helpers.error("Fehler", "Mitarbeiter konnte nicht verknüpft werden.");
        }
      });
    },
    forceEmployeeUpdateFromCandidate(candidate) {
      let view = this;
      let payload = {
        candidateId: candidate.id,
        remoteId: candidate.remoteId
      };

      let url = "/sec/employee/candidate/update/force";
      this.api.post(url, payload).then((response) => {
        console.log(response);
        if (response.status == 200) {
          view.$helpers.success("Gespeichert", "Mitarbeiter gespeichert!");
          Object.values(view.candidates[view.importJob.id]).forEach((elem, index) => {
            if (this.employeeCandidateDiffId === elem.id) {
              elem.actionRequired = 0;
            }
          });
          const count = view.candidates[view.importJob.id].filter(elem => {
            if (elem.actionRequired === 1) {
              return true;
            }
            return false;
          }).length;
          if (count === 0) {
            view.refresh();
          }
        }
      }).catch((error) => {
        if (error.response.data.data && error.response.data.data.email) {
          view.$helpers.error("Fehler", "E-Mail Adresse " + error.response.data.data.email + " existiert bereits für einen anderen Mitarbeiter.");
        } else {
          view.$helpers.error("Fehler", "Mitarbeiter Update fehlgeschlagen.");
        }
      });
    },
    doEmployeeUpdateWithoutCoreDataFromCandidate(candidate) {
      let view = this;
      let payload = {
        candidateId: candidate.id,
        remoteId: candidate.remoteId
      };

      let url = "/sec/employee/candidate/update/nocoredata";
      this.api.post(url, payload).then((response) => {
        console.log(response);
        if (response.status == 200) {
          view.$helpers.success("Gespeichert", "Mitarbeiter gespeichert!");
          Object.values(view.candidates[view.importJob.id]).forEach((elem, index) => {
            if (this.employeeCandidateDiffId === elem.id) {
              elem.actionRequired = 0;
            }
          });
          const count = view.candidates[view.importJob.id].filter(elem => {
            if (elem.actionRequired === 1) {
              return true;
            }
            return false;
          }).length;
          if (count === 0) {
            view.refresh();
          }
        }
      }).catch((error) => {
        if (error.response.data.data.email) {
          view.$helpers.error("Fehler", "E-Mail Adresse " + error.response.data.data.email + " existiert bereits für einen anderen Mitarbeiter.");
        } else {
          view.$helpers.error("Fehler", "Mitarbeiter Update fehlgeschlagen.");
        }
      });
    },

    // trigger CSV export
    triggerCsvExport(remoteId) {
      let view = this;

      view.loadingExport = true;

      let url = '/api/sec/employeesync/export';
      let baseurl = url;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);
      url = this.$helpers.appendParam("softwareId", view.companyData.softwareId, url, baseurl);
      url = this.$helpers.appendParam("remoteIds", [remoteId], url, baseurl);

      this.axios({
        method: "get",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then((response) => {
        view.$helpers.info(
          "Info",
          "Export in das LV Tool wurde erfolgreich angestoßen."
        );
        view.skipEmployeeCandidate();
        view.closeExternalDataChangedModal();
        view.loadingExport = false;
      }).catch((e) => {
        view.exporting = false;
        view.$helpers.error(
          "Fehler",
          "Fehler beim Erzeugen des Exports."
        );
        view.loadingExport = false;
      });
    },
    // CSV download
    downloadCSV(remoteId) {
      let view = this;
      view.loadingDownload = true;

      let url = '/api/sec/employeesync/getexport';
      let baseurl = url;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);
      url = this.$helpers.appendParam("softwareId", view.companyData.softwareId, url, baseurl);
      url = this.$helpers.appendParam("remoteIds", [remoteId], url, baseurl);

      this.axios({
        method: "get",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then((response) => {
        let csv = response.data.data;
        view.$helpers.forceFileDownload(view.$helpers.b64DecodeUnicode(csv), "Export.csv");
        view.skipEmployeeCandidate();
        view.closeExternalDataChangedModal();
        view.loadingDownload = false;
      }).catch((e) => {
        view.$helpers.error(
          "Fehler",
          "Fehler beim Erzeugen des Exports."
        );
        view.loadingDownload = false;
      });
    },

    getCandidates(employee) {
      let url = "/sec/employee/proxyMDCoreCandidate";
      url = this.$helpers.appendParam("firstName", employee.firstName, url, "/sec/employee/proxyMDCoreCandidate");
      url = this.$helpers.appendParam("lastName", employee.lastName, url, "/sec/employee/proxyMDCoreCandidate");
      if (employee.dob != null) url = this.$helpers.appendParam("dob", this.$helpers.extractDateString(employee.dob), url, "/sec/employee/proxyMDCoreCandidate");
      if (employee.socialsecurity != null) url = this.$helpers.appendParam("socialsecurity", employee.socialsecurity, url, "/sec/employee/proxyMDCoreCandidate");
      console.log(url);
      return this.api.get(url);
    },
    transformCandidateResult(candidateResult) {
      let candidates = candidateResult.candidates;
      let matches = candidateResult.matches;
      let selectableItems = [];
      candidates.forEach((candidate) => {
        let bd = candidate.date_of_birth != null ? this.$helpers.getMomentFromStringTZ(candidate.date_of_birth).format("DD.MM.YYYY") : "";
        let item = {
          label: candidate.firstname + " " + candidate.lastname + " " + bd + " (Übereinstimmung: " + candidate.score + "%)",
          value: candidate.id,
          score: candidate.score,
        };
        selectableItems.push(item);
      });
      matches.forEach((candidate) => {
        let bd = candidate.date_of_birth != null ? this.$helpers.getMomentFromStringTZ(candidate.date_of_birth).format("DD.MM.YYYY") : "";
        let item = {
          label: candidate.firstname + " " + candidate.lastname + " " + bd + " 100%",
          value: candidate.id,
          score: 100,
        };
        selectableItems.push(item);
      });
      selectableItems.sort((a, b) => {
        return -(a.score - b.score);
      });
      return selectableItems;
    },
    openCandidateChooserPopup(newEmployee, selectableItems) {
      let view = this;
      let entries = [];

      selectableItems.unshift({
        label: "Mitarbeiter neu anlegen",
        value: null,
      });

      let description =
        "Der externe Mitarbeiter konnte keinem internen Mitarbeiter zugeordnet werden. <br/> <br/> " +
        "Es wurden Mitarbeiter in ihrer Firma mit ähnlichen Daten gefunden. <br/> " +
        "Sie können den Mitarbeiter neu anlegen oder mit einem bereits bestehenden Mitarbeiter verknüpfen.";

      entries.push({
        label: "Bitte auswählen",
        key: "remoteId",
        hidden: false,
        type: "dropdownsearch",
        value: null,
        selectMultiple: false,
        dropdownSearchState: {
          toggled: false,
          displayedItems: [],
          input: "",
          items: selectableItems,
          selectMultiple: false,
          selectedItems: [],
          selectFirst: true,
          styleLight: true,
        },
      });

      return new Promise((resolve, accept) => {
        view.$helpers.OpenPopup({
          type: "datafill",
          componentState: {
            entries: entries,
            description: description,
            selectCallback: function (item) {
              resolve(item.remoteId);
            },
            negativeButtonLabel: "Nicht Importieren",
            deleteCallback: function (item) {
              resolve(0);
            },
          },
          header: "Mitarbeiter prüfen: " + newEmployee.firstName + " " + newEmployee.lastName,
          centered: true,
          expanded: true,
        });
      });
    },
    openSingleCandidateCreatorPopup(newEmployee) {
      let view = this;
      let entries = [];

      let description =
        "Der externe Mitarbeiter konnte keinem internen Mitarbeiter zugeordnet werden. <br/> <br/> " +
        "Sie können den Mitarbeiter neu anlegen.";

      return new Promise((resolve, accept) => {
        view.$helpers.OpenPopup({
          type: "datafill",
          componentState: {
            entries: entries,
            description: description,
            positiveButtonLabel: "Neu anlegen",
            selectCallback: function (item) {
              resolve(null);
            },
            negativeButtonLabel: "Nicht Importieren",
            deleteCallback: function (item) {
              resolve(0);
            },
          },
          header: "Mitarbeiter prüfen: " + newEmployee.firstName + " " + newEmployee.lastName,
          centered: true,
          expanded: true,
        });
      });
    },
    openUnknownIssuePopup(candidate) {
      let view = this;

      let style = { 'padding-left': '10px', 'margin': '0px' };
      let valueStyle = { 'margin': '0px' };

      let entries = [
        {key: 'importId', type: 'output', styles: style, valueStyles: valueStyle, value: candidate.employeeImportId, label:'Import ID'},
        {key: 'internalId', type: 'output', styles: style, valueStyles: valueStyle, value: candidate.remoteId, label:'Interne ID'},
        {key: 'externalId', type: 'output', styles: style, valueStyles: valueStyle, value: candidate.externalId, label:'Externe ID'},
        {key: 'firstname', type: 'output', styles: style, valueStyles: valueStyle, value: candidate.firstName, label:'Vorname'},
        {key: 'lastname', type: 'output', styles: style, valueStyles: valueStyle, value: candidate.lastName, label:'Nachname'},
        {key: 'dob', type: 'output', styles: style, valueStyles: valueStyle, value: candidate.dob, label:'Geburtsdatum'},
        {key: 'socialsecurity', type: 'output', styles: style, valueStyles: valueStyle, value: candidate.socialsecurity, label:'Sozialversicherungsnummer'},
      ];

      let description =
        "Das Problem bei diesem Import konnte nicht erkannt werden. <br/> " +
        "Bitten wenden Sie sich an unser Team, oder prüfen Sie Ihre Eingaben und versuchen Sie den Import erneut!";

      return new Promise((resolve, accept) => {
        view.$helpers.OpenPopup({
          type: "datafill",
          componentState: {
            entries: entries,
            description: description,
            selectCallback: function (item) {
              view.skipEmployeeCandidate();
            },
            negativeButtonLabel: "Abbrechen",
            deleteCallback: function (item) {},
          },
          header: "Unbekanntes Problem!",
          centered: true,
          expanded: true,
        });
      });
    },
    openInputIssuePopup(candidate) {
      let view = this;

      let style = { 'padding-left': '10px', 'margin': '0px' };
      let valueStyle = { 'margin': '0px' };

      let entries = [];
      let reasonArray = candidate.actionRequiredReason.split(":");

      let description =
        "Bei Ihrem Import gab es ein Problem mit Ihren Eingaben. <br/> " +
        "Bitte prüfen und ändern Sie Ihre Eingaben und versuchen Sie den Import erneut! <br/> <br/>";

      if (reasonArray[0] === "inputdata") {
        if (reasonArray[1] === "email") {
          description += "Die E-Mail-Adresse existiert bereits in unserem System.";

          if (reasonArray.length >= 3) {
            entries.push({
              key: 'issue',
              type: 'output',
              styles: style,
              valueStyles: valueStyle,
              value: reasonArray[2],
              label: reasonArray[1]
            });
          }
        }
      } else if (reasonArray[0] === "missingdata") {
        description += "Es fehlen folgende Daten:<br/>";
        let missingDataArray = reasonArray[1].split(",");
        missingDataArray.forEach(missingData => {
          switch (missingData) {
            case "firstName": description += "Vorname<br/>"; break;
            case "lastName": description += "Nachname<br/>"; break;
            case "dob": description += "Geburtsdatum<br/>"; break;
            case "socialsecurity": description += "Sozialversicherungsnummer<br/>"; break;
            case "email": description += "E-Mail-Adresse<br/>"; break;
            default: break;
          }
        });
      }

      return new Promise((resolve, accept) => {
        view.$helpers.OpenPopup({
          type: "datafill",
          componentState: {
            entries: entries,
            description: description,
            selectCallback: function (item) {
              view.skipEmployeeCandidate();
            },
            negativeButtonLabel: "Abbrechen",
            deleteCallback: function (item) {

            },
          },
          header: "Eingabe Problem!",
          centered: true,
          expanded: true,
        });
      });
    },
    openExternalDataChangedIssuePopup(candidate) {
      let view = this;

      let style = { 'padding-left': '10px', 'margin': '0px' };
      let valueStyle = { 'margin': '0px' };

      let entries = [];
      let reasonArray = candidate.actionRequiredReason.split(":");

      let description =
        "Beim Import aus " + view.importJob.method + " wurden Änderungen erkannt. <br/> " +
        "Bitte prüfen und bestätigen Sie die geänderten Daten, um Sie in ML zu übernehmen. <br/> <br/>";

      if (reasonArray[0] === "inputdata") {
        if (reasonArray[1] === "email") {
          description += "Die E-Mail-Adresse existiert bereits in unserem System.";

          if (reasonArray.length >= 3) {
            entries.push({
              key: 'issue',
              type: 'output',
              styles: style,
              valueStyles: valueStyle,
              value: reasonArray[2],
              label: reasonArray[1]
            });
          }
        }
      } else if (reasonArray[0] === "missingdata") {
        description += "Es fehlen folgende Daten:<br/>";
        let missingDataArray = reasonArray[1].split(",");
        missingDataArray.forEach(missingData => {
          switch (missingData) {
            case "firstName": description += "Vorname<br/>"; break;
            case "lastName": description += "Nachname<br/>"; break;
            case "dob": description += "Geburtsdatum<br/>"; break;
            case "socialsecurity": description += "Sozialversicherungsnummer<br/>"; break;
            case "email": description += "E-Mail-Adresse<br/>"; break;
            default: break;
          }
        });
      }

      return new Promise((resolve, accept) => {
        view.$helpers.OpenPopup({
          type: "datafill",
          componentState: {
            entries: entries,
            description: description,
            selectCallback: function (item) {
              // TODO: trigger saving of employee
            },
            positiveButtonLabel: "Änderungen übernehmen",
            negativeButtonLabel: "Änderungen ablehnen",
            deleteCallback: function (item) {
              view.skipEmployeeCandidate();
            },
          },
          header: "Datenänderung in " + this.importJob.method + "!",
          centered: true,
          expanded: true,
        });
      });
    },
    openCoreDataIssuePopup(candidate, diff) {
      let view = this;

      let style = { 'padding-left': '10px', 'margin': '0px' };
      let valueStyle = { 'margin': '0px' };

      let entries = [];

      let description =
        "Bei diesem Import wurden geänderte Kerndaten gefunden. <br/> " +
        "Bitte prüfen Sie die Eingaben und führen Sie eine Aktion durch! <br/> <br/>";

      description += "Möchten Sie folgende Daten übernehmen? <br/>";
      Object.values(diff).forEach((row) => {
        row.displayName = "Das Feld " + row.key;
        switch (row.key) {
          case "firstName": row.displayName = "Der Vorname"; break;
          case "lastName": row.displayName = "Der Nachname"; break;
          case "dob": row.displayName = "Das Geburtsdatum"; break;
          case "socialsecurity": row.displayName = "Die Sozialversicherungsnummer"; break;
          default: break;
        }

        description += row.displayName + " wird von <strong>" + row.database + "</strong> auf <strong>" + row.candidate + "</strong> geändert!<br/>";
      });

      return new Promise((resolve, accept) => {
        view.$helpers.OpenPopup({
          type: "datafill",
          componentState: {
            entries: entries,
            description: description,
            selectCallback: function (item) {
              view.forceEmployeeUpdateFromCandidate(candidate);
            },
            deleteCallback: function (item) {
              view.doEmployeeUpdateWithoutCoreDataFromCandidate(candidate);
            },
            positiveButtonLabel: "Kerndaten übernehmen",
            negativeButtonLabel: "Kerndaten NICHT übernehmen",
          },
          header: "Änderung der Kerndaten!",
          centered: true,
          expanded: true,
        });
      });
    },

    getEmployeeSyncSettings() {
      return this.$helpers.GetCall('/api/sec/employeesync/settings').then((response) => {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(response.data, false));
        });
      }).catch((e) => {
        return new Promise((resolve, reject) => {
          reject(e);
        });
      });
    },
    getEmployeeSyncSettingsData() {
      return this.$helpers.GetCall('/api/sec/employeesync/data').then((response) => {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(response.data, false));
        });
      }).catch((e) => {
        return new Promise((resolve, reject) => {
          reject(e);
        });
      });
    },

    openCSVImportPopup() {
      let view = this;

      let description =
        "Bitte laden Sie hier den Export aus Ihrem Lohnverrechnungstool hoch. <br/> " +
        "Bitte vergewissern Sie sich, dass der Export mit UTF-8 Encoding durchgeführt wurde. <br/><br/>" +
        "Dieser Import verwendet die Einstellungen, welche in Einstellungen " +
        "<i class='fas fa-arrow-right' style='cursor: auto'></i>" + " Mitarbeiter Sync getroffen wurden.";

      let dataEntries = [
        {
          label: "CSV Datei",
          accept: ".csv",
          key: "file",
          hidden: false,
          type: "file",
          value: null,
          styles: {
            "padding-left": "10px",
          }
        },
      ];

      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        componentState: {
          entries: dataEntries,
          description: description,
          selectCallback: function (importf) {
            if (importf.file.length > 0) {

              let read = new FileReader();
              read.readAsBinaryString(importf.file[0]);
              read.onloadend = function(){
                view.sendEmployeeImport(importf.file[0], read.result);
              }
            } else {
              view.$helpers.error(
                "Fehler",
                "Es wurde keine Import Datei ausgewählt"
              );
            }
          },
        },
        header: "Mitarbeiter Daten mit " + view.software + " Export importieren",
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },
    sendEmployeeImport(file, content) {
      let view = this;
      let payload = {
        source: view.software,
        filename: file.name,
        file: btoa(content)
      };

      let url = "/sec/employeesync/csvimport";
      return this.api.post(url, payload)
        .then((response) => {
          if (response.status === 202) {
            view.$helpers.success("Erfolg", "Mitarbeiter Import Job wurde angestoßen!");
            this.refresh();
          }
        }).catch((error) => {
          view.$helpers.error("Fehler", "Mitarbeiter Import konnte nicht durchgeführt werden: " + error.response.data);
          this.refresh();
        });
    },

    OpenFilterDropdown($event) {
      var view = this;
      var target = $event.currentTarget;

      this.$helpers.OpenPopup({
        elRelative: target,
        type: "list",
        componentState: {
          entries: view.filterDropdownOptions,
        },
        remainAfterSelect: true,
        pointerDirection: 0,
        iconsLeft: true,
        selectCallback: function ($event, item, dropdown) {
          item.toggled = !item.toggled;
        },
      });
    },
    LoadFilterDropdown() {
      var view = this;
      this.filterDropdownOptions = [];
      view.filterDropdownOptions.push({
        label: "Aktion",
        key: "action",
        isHeader: true,
      });
      view.filterDropdownOptions.push({
        label: "Aktion erforderlich",
        key: "action_required",
        toggled: true,
        color: "var(--ml)",
      });
      view.filterDropdownOptions.push({
        label: "Keine Aktion erforderlich",
        key: "no_action_required",
        toggled: true,
        color: "var(--ml)",
      });
      view.filterDropdownOptions.push({
        label: "Methoden",
        key: "methods",
        isHeader: true,
      });
      view.filterDropdownOptions.push({
        label: "SNS",
        key: "sns",
        toggled: false,
        color: "var(--ml)",
      });
      view.filterDropdownOptions.push({
        label: "MD",
        key: "md",
        toggled: true,
        color: "var(--ml)",
      });
      view.filterDropdownOptions.push({
        label: "CORE",
        key: "core",
        toggled: true,
        color: "var(--ml)",
      });
      view.filterDropdownOptions.push({
        label: "Externes LV Tool",
        key: "external",
        toggled: true,
        color: "var(--ml)",
      });
      view.filterDropdownOptions.push({
        label: "Andere",
        key: "other",
        toggled: true,
        color: "var(--ml)",
      });
      // view.filterDropdownOptions.push({
      //   label: "BMD",
      //   key: "bmd",
      //   toggled: true,
      //   color: "#000",
      // });
      // view.filterDropdownOptions.push({
      //   label: "DPW",
      //   key: "dpw",
      //   toggled: true,
      //   color: "#000",
      // });
      // view.filterDropdownOptions.push({
      //   label: "SOTEC",
      //   key: "sotec",
      //   toggled: true,
      //   color: "#000",
      // });
    },
    filterJobs() {
      let view = this;
      view.filteredJobs = [];
      Object.values(view.jobs).forEach((job) => {
        let noaction = view.filterDropdownOptions.find((option) => option.key === "no_action_required");
        let action = view.filterDropdownOptions.find((option) => option.key === "action_required");
        if ((noaction.toggled && job.actionRequired === 0) || (action.toggled && job.actionRequired === 1)) {
          let option = view.filterDropdownOptions.find((option) => option.key === job.method.toLowerCase());
          let external = view.filterDropdownOptions.find((option) => option.key === "external");
          let other = view.filterDropdownOptions.find((option) => option.key === "other");

          if (option !== undefined) {
            if (option.toggled === true) {
              view.filteredJobs.push(job);
            }
          } else if (Object.values(view.softwareIds).includes(job.method.toUpperCase())) {
            if (external.toggled) {
              view.filteredJobs.push(job);
            }
          } else if (other.toggled) {
            view.filteredJobs.push(job);
          }
        }
      });
    },
    GetProfilePictureUrl(employee, size = null, urlOnly = false) {
      let view = this;
      if (employee.employeePicture_fileId == null) {
        var port = location.port;
        var url =
          location.protocol +
          "//" +
          location.hostname +
          "/img/avatar.png";
        if (port != "80" && port != "443") {
          url =
            location.protocol +
            "//" +
            location.hostname +
            ":" +
            location.port +
            "/img/avatar.png";
        }

        if (urlOnly) return url;
        return "var(--contrast-1) no-repeat center/cover url(/img/avatar.png)";
      } else {
        var imgUrl =
          employee.employeePicture_server +
          "/api/file?id=" +
          employee.employeePicture_fileId +
          "&accessToken=" +
          employee.employeePicture_accessToken +
          "&s=" +
          this.imgCacheTimestamp;
        if (size) imgUrl = imgUrl + "&thumb=" + size;
        if (urlOnly) return imgUrl;
        return "var(--contrast-1) no-repeat center/cover  url(" + imgUrl + ")";
      }
    },
    formatDate(dateString) {
      if (dateString != null) {
        let d = new Date(dateString);
        return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear();
      }
      return null;
    },
    setupUIDataMaps() {
      this.uiDataMap.employeeCategories.forEach(item => this.employeeCategoryArray[item.id] = item.label_de);
      this.uiDataMap.employmentTypes.forEach(item => this.employmentTypeArray[item.id] = item.label_de);
      this.uiDataMap.contractTypes.forEach(item => this.contractTypeArray[item.id] = item.label_de);
      this.uiDataMap.paymentTypes.forEach(item => this.paymentTypeArray[item.id] = item.label_de);
      this.uiDataMap.wageTypes.forEach(item => this.wageTypeArray[item.id] = item.label_de);
    },

    // fibu
    loadFinancialAccountingGroups: function(){
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      view.$helpers.GetCall('/api/financialaccountinggroups', params).then((response) => {
        let data = response.data.data;
        let tmp = {};
        for (let i = 0; i < data.length; i++) {
          let item = {
            id: data[i].id,
            number: data[i].number,
            name: data[i].name,
          };
          tmp[data[i].id] = item;
        }
        view.financialAccountingGroups = tmp;
      }).catch((e) => {
        view.loading = false;
        console.log(e);
      });
    },
    loadAndOpenCandidates(job) {
      let view = this;
      view.loadEmployeeImportCandidates(job);
      view.openAllCandidatesModal(job);
    },
    openAllCandidatesModal(job) {
      this.inspectedCandidatesJob = job;
      this.$refs["modal-all-candidates"].show();
    },
    closeAllCandidatesModal() {
      this.inspectedCandidatesJob = null;
      this.$refs["modal-all-candidates"].hide();
    },
    openSingleCandidatesModal(candidate) {
      this.inspectedCandidate = candidate;
      this.$refs["modal-single-candidate"].show();
    },
    closeSingleCandidatesModal() {
      this.$refs["modal-single-candidate"].hide();
    },
    openExternalDataChangedModal(candidate) {
      let view = this;
      view.externalImportIssueFields = [];
      view.externalImportCandidate = candidate;
      let reasonArray = candidate.actionRequiredReason.split(":");
      let fieldsArray = reasonArray[1].split(",");

      let employee = view.employees.find(emp => emp.id === candidate.remoteId);
      view.externalImportEmployee = employee;
      let contract = view.employeeContracts[employee.id] !== undefined ? view.employeeContracts[employee.id][candidate.storeId] : undefined;

      view.profilePictureUrl = view.GetProfilePictureUrl(employee, 'large', true);

      fieldsArray.forEach(field => {
        switch (field) {
          //firstName
          case "firstName":
          case "fn":
            view.externalImportIssueFields.push({
              field: "Vorname",
              employee: view.externalImportEmployee.firstName,
              candidate: view.externalImportCandidate.firstName
            });
            break;
          //lastName
          case "lastName":
          case "ln":
            view.externalImportIssueFields.push({
              field: "Nachname",
              employee: view.externalImportEmployee.lastName,
              candidate: view.externalImportCandidate.lastName
            });
            break;
          //dob
          case "dob":
            view.externalImportIssueFields.push({
              field: "Geburtsdatum",
              employee: this.formatDate(view.externalImportEmployee.dob),
              candidate: this.formatDate(view.externalImportCandidate.dob)
            });
            break;
          //socialsecurity
          case "socialsecurity":
          case "ss":
            view.externalImportIssueFields.push({
              field: "Sozialversicherungsnummer",
              employee: view.externalImportEmployee.socialsecurity,
              candidate: view.externalImportCandidate.socialsecurity
            });
            break;
          //gender
          case "gender":
            view.externalImportIssueFields.push({
              field: "Geschlecht",
              employee: view.genderArray[view.externalImportEmployee.genderId],
              candidate: view.genderCandidateArray.hasOwnProperty(view.externalImportCandidate.gender) ?
                view.genderArray[view.genderCandidateArray[view.externalImportCandidate.gender]] :
                view.genderArray[0]
            });
            break;
          //email
          case "email":
            view.externalImportIssueFields.push({
              field: "Email",
              employee: view.externalImportEmployee.email,
              candidate: view.externalImportCandidate.email
            });
            break;
          //civilStatusId
          case "civilStatusId":
          case "csid":
            view.externalImportIssueFields.push({
              field: "Familienstand",
              employee: view.civilStatusArray[view.externalImportEmployee.civilStatusId],
              candidate: view.civilStatusArray[view.externalImportCandidate.civilStatusId]
            });
            break;
          //citizenTypeId
          case "citizenTypeId":
          case "ctid":
            view.externalImportIssueFields.push({
              field: "Bürgertyp",
              employee: view.citizenTypeArray[view.externalImportEmployee.citizenTypeId],
              candidate: view.citizenTypeArray[view.externalImportCandidate.citizenTypeId]
            });
            break;
          //nationality
          case "nationality":
          case "nat":
            view.externalImportIssueFields.push({
              field: "Nationalität",
              employee: view.countryArray[view.externalImportEmployee.citizenshipId],
              candidate: view.countryArray[view.externalImportCandidate.nationality]
            });
            break;
          //address
          case "address":
            view.externalImportIssueFields.push({
              field: "Adresse",
              employee: view.externalImportEmployee.address,
              candidate: view.externalImportCandidate.address
            });
            break;
          //postalCode
          case "postalCode":
          case "pc":
            view.externalImportIssueFields.push({
              field: "PLZ",
              employee: view.externalImportEmployee.postalCode,
              candidate: view.externalImportCandidate.postalCode
            });
            break;
          //city
          case "city":
            view.externalImportIssueFields.push({
              field: "Stadt",
              employee: view.externalImportEmployee.city,
              candidate: view.externalImportCandidate.city
            });
            break;
          //country
          case "country":
            view.externalImportIssueFields.push({
              field: "Land",
              employee: view.countryArray[view.externalImportEmployee.countryId],
              candidate: view.countryArray[view.externalImportCandidate.country]
            });
            break;
          //mobilephone
          case "mobilephone":
          case "mp":
            view.externalImportIssueFields.push({
              field: "Mobiltelefon",
              employee: view.externalImportEmployee.mobilephone,
              candidate: view.externalImportCandidate.mobilephone
            });
            break;
          //homephone
          case "homephone":
          case "hp":
            view.externalImportIssueFields.push({
              field: "Haustelefon",
              employee: view.externalImportEmployee.homephone,
              candidate: view.externalImportCandidate.homephone
            });
            break;
          //bankAccountHolder
          case "bankAccountHolder":
          case "bah":
            view.externalImportIssueFields.push({
              field: "Kontoinhaber",
              employee: view.externalImportEmployee.bankAccountHolder,
              candidate: view.externalImportCandidate.bankAccountHolder
            });
            break;
          //iban
          case "iban":
            view.externalImportIssueFields.push({
              field: "IBAN",
              employee: view.externalImportEmployee.iban,
              candidate: view.externalImportCandidate.iban
            });
            break;
          //financialAccountingGroupId
          case "financialAccountingGroupId":
          case "fagid":
            view.externalImportIssueFields.push({
              field: "FIBU-Kreis",
              employee: (view.externalImportEmployee.financialAccountingGroupId != null && view.financialAccountingGroups[view.externalImportEmployee.financialAccountingGroupId] != null)
                ? view.financialAccountingGroups[view.externalImportEmployee.financialAccountingGroupId].name : null,
              candidate: (view.externalImportCandidate.financialAccountingGroupNumber != null && view.fibuByNumber[view.externalImportCandidate.financialAccountingGroupNumber] != null)
                ? view.fibuByNumber[view.externalImportCandidate.financialAccountingGroupNumber].name : null,
            });
            break;
          //allocationGroup
          case "allocationGroup":
          case "ag":
            view.externalImportIssueFields.push({
              field: "Abrechnungskreis",
              employee: view.externalImportEmployee.allocationGroup,
              candidate: view.externalImportCandidate.allocationGroup
            });
            break;
          //bic
          case "bic":
            view.externalImportIssueFields.push({
              field: "BIC",
              employee: view.externalImportEmployee.bic,
              candidate: view.externalImportCandidate.bic
            });
            break;
          //workEntryDate
          case "workEntryDate":
          case "wed":
            view.externalImportIssueFields.push({
              field: "Ersteintrittsdatum",
              employee: this.formatDate(view.externalImportEmployee.workEntryDate),
              candidate: this.formatDate(view.externalImportCandidate.workEntryDate)
            });
            break;
          //externalId
          case "externalId":
            view.externalImportIssueFields.push({
              field: "Externe ID",
              employee: view.importJob.method == "BMD" ? view.externalImportEmployee.bmdId : "",
              candidate: view.externalImportCandidate.externalId
            });
            break;
          //mdPersonnelId
          case "mdPersonnelId":
          case "mdpid":
            view.externalImportIssueFields.push({
              field: "Personalnummer",
              employee: view.externalImportEmployee.mdPersonnelId,
              candidate: view.externalImportCandidate.personnelId
            });
            break;
          //paymentType
          case "paymentType":
          case "pt":
            view.externalImportIssueFields.push({
              field: "Zahlungstyp",
              employee: contract != null ? view.paymentTypeArray[contract.paymentType] : "",
              candidate: view.paymentTypeArray[view.externalImportCandidate.paymentType]
            });
            break;
          //wageType
          case "wageType":
          case "wt":
            view.externalImportIssueFields.push({
              field: "Lohntyp",
              employee: contract != null ? view.wageTypeArray[contract.wageType] : "",
              candidate: view.wageTypeArray[view.externalImportCandidate.wageType]
            });
            break;
          //payment
          case "payment":
            view.externalImportIssueFields.push({
              field: "Bezahlung",
              employee: contract != null ? contract.payment / 100.0 : "",
              candidate: view.externalImportCandidate.payment / 100.0
            });
            break;
          //collectiveContractId
          case "collectiveContractId":
          case "ccid":
            view.externalImportIssueFields.push({
              field: "Kollektivvertrag ID",
              employee: contract != null ? view.allowedContracts[contract.storeId][contract.collectiveContractId].collectiveContractId : "",
              candidate: view.externalImportCandidate.collectiveContractId
            });
            break;
          //jobName
          case "jobName":
          case "jn":
            view.externalImportIssueFields.push({
              field: "Beruf",
              employee: contract != null ? contract.jobName : "",
              candidate: view.externalImportCandidate.jobName
            });
            break;
          //contractType
          case "contractType":
          case "ct":
            view.externalImportIssueFields.push({
              field: "Vertragstyp",
              employee: contract != null ? this.contractTypeArray[contract.contractType] : "",
              candidate: this.contractTypeArray[view.externalImportCandidate.contractType]
            });
            break;
          //contractStart
          case "contractStart":
          case "cs":
            view.externalImportIssueFields.push({
              field: "Vertragsbeginn",
              employee: contract != null ? this.formatDate(contract.from) : "",
              candidate: this.formatDate(view.externalImportCandidate.contractStart)
            });
            break;
          //contractEnd
          case "contractEnd":
          case "ce":
            view.externalImportIssueFields.push({
              field: "Vertragsende",
              employee: contract != null ? this.formatDate(contract.to) : "",
              candidate: this.formatDate(view.externalImportCandidate.contractEnd)
            });
            break;
          //employeeCategory
          case "employeeCategory":
          case "ec":
            view.externalImportIssueFields.push({
              field: "Mitarbeiter Kategorie",
              employee: contract != null ? this.employeeCategoryArray[contract.employeeCategory] : "",
              candidate: this.employeeCategoryArray[view.externalImportCandidate.employeeCategory]
            });
            break;
          //employmentType
          case "employmentType":
          case "et":
            view.externalImportIssueFields.push({
              field: "Anstellungsart",
              employee: contract != null ? this.employmentTypeArray[contract.employmentType] : "",
              candidate: this.employmentTypeArray[view.externalImportCandidate.employmentType]
            });
            break;
          //weekTimeRegular
          case "weekTimeRegular":
          case "wtr":
            view.externalImportIssueFields.push({
              field: "Wochenstunden",
              employee: contract != null ? contract.weekTimeRegular / 60.0 : "",
              candidate: view.externalImportCandidate.weekTimeRegular / 60.0
            });
            break;
          //monthTimeRegular
          case "monthTimeRegular":
          case "mtr":
            view.externalImportIssueFields.push({
              field: "Monatsstunden",
              employee: contract != null ? (Math.round((view.allowedContracts[contract.storeId][contract.collectiveContractId].monthTimeRegular / 60.0 + Number.EPSILON) * 100) / 100) : "",
              candidate: view.externalImportCandidate.monthTimeRegular ? (Math.round((view.externalImportCandidate.monthTimeRegular / 60.0 + Number.EPSILON) * 100) / 100) : ""
            });
            break;
          //weekWorkDaysRegular
          case "weekWorkDaysRegular":
          case "wwdr":
            view.externalImportIssueFields.push({
              field: "Arbeitstage pro Woche",
              employee: contract != null ? contract.weekWorkDaysRegular : "",
              candidate: view.externalImportCandidate.weekWorkDaysRegular
            });
            break;
          //vacationTime
          case "vacationTime":
          case "vt":
            view.externalImportIssueFields.push({
              field: "Urlaubstage",
              employee: contract != null ? contract.vacationTime / 60 / 24 : "",
              candidate: view.externalImportCandidate.vacationTime
            });
            break;
          default: break;
        }


      });

      this.$refs["modal-external-data-changed"].show();
    },
    closeExternalDataChangedModal() {
      this.$refs["modal-external-data-changed"].hide();
    },
    openExternalDataChangedMatchingModal() {
      this.$refs["modal-external-data-changed-matching"].show();
    },
    closeExternalDataChangedMatchingModal() {
      this.$refs["modal-external-data-changed-matching"].hide();
    },
  },
}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>

.xColumn3 {
  width: 400px;
  text-align: left;
  float: left;
  margin-left: 25px;
  margin-right: 25px;
  overflow-y: auto;
  max-height: 800px;
}

.btn-ml {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}

tr:hover .btn-ml {
  color: #fff;
  background-color: var(--ml-alt);
  border-color: #fff;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}

.emp-core-data {
  display: flex;
  align-items: center;
  padding: 5px;
}

.emp-core-data > div {
  padding-right: 5px;
}


</style>
